<i18n>
{
  "en": {
    "aboutDeposit": {
      "title": "Send only {0} to this deposit address.",
      "description": "Sending coin or token other than {0} to this address may result in the loss of your deposit.",
      "description2": "For security reasons, generating new address every 60 minutes.",
      "description3": "Do not send amounts less than the minimum deposit amount, otherwise your transaction will not be found and you will lose funds. Consider the transaction fee.",
      "description4": "Send the transaction on time. If you sent a transaction after the timer expired, please contact tickets.",
      "memo": "For this coin, you must fill in the MEMO/Destination Tag field when sending a transaction. Otherwise, you will lose funds."
    },
    "copied": "Copied"
  },
  "zh": {
    "aboutDeposit": {
      "title": "只發送 {0} 到這個存款地址。",
      "description": "向該地址發送除 {0} 以外的硬幣或代幣可能會導致您的存款丟失。",
      "description2": "一筆交易發送金額，否則不計算在內。",
      "description3": "準確發送支付信息中註明的金額，否則係統不會檢測到您的支付。請考慮交易費用。",
      "description4": "按時發送交易。如果您在定時器到期後發送交易，請聯繫支持。",
      "memo": "對於這個幣，發送交易的時候一定要填寫MEMO字段，否則會損失資金。"
    },
    "waiting": "等待交易",
    "founded": "交易被發現",
    "founded2": "等待確認：{1} 中的 {0}",
    "makepayment": "到期時間",
    "completed": "支付完成",
    "expired": "付款已過期",
    "back": "返回存款",
    "copied": "已復制"
  },
  "kr": {
    "aboutDeposit": {
      "title": "이 예금 주소로 {0}만 보내십시오.",
      "description": "이 주소로 {0} 이외의 코인 또는 토큰을 보내면 보증금이 손실될 수 있습니다.",
      "description2": "하나의 트랜잭션으로 금액을 보내십시오. 그렇지 않으면 계산되지 않습니다.",
      "description3": "지불 정보에 표시된 금액을 정확히 보내주십시오. 그렇지 않으면 시스템이 귀하의 지불을 감지하지 못합니다. 거래 수수료를 고려하십시오.",
      "description4": "제시간에 트랜잭션을 보내십시오. 타이머가 만료된 후 트랜잭션을 보낸 경우 지원에 문의하십시오.",
      "memo": "이 코인의 경우 거래를 보낼 때 MEMO 필드를 작성해야 합니다. 그렇지 않으면 자금을 잃게 됩니다."
    },
    "waiting": "트랜잭션 대기 중",
    "founded": "트랜잭션을 찾았습니다",
    "founded2": "확인 대기 중: {1}의 {0}",
    "makepayment": "결제가 에 만료됩니다",
    "completed": "결제가 완료되었습니다",
    "expired": "지급이 만료되었습니다",
    "back": "예금으로 돌아가기",
    "copied": "복사"
  }
}
</i18n>

<template>
  <Page :is-page-loaded="dataLoadingStatus" >
    <PageContainer :no-line="true" :style="{ padding: $store.state.Index.isMobile ? 0 : '0 15px' }">

      <div class="expired" v-if="depositInfo.deadline - Date.now() <= 0 && !depositInfo.isFound && !depositInfo.isCompleted">
        <font-awesome-icon style="font-size: 100px; color: #D02424;" :icon="['fas', 'times-circle']" />
        <div style="margin: 15px 0;font-size: 25px;">
          <span>{{ $t('expired') }}</span>
        </div>
        <router-link
            :to="$linkCreator($i18n.locale, 'dashboard/wallets/deposit')"
            exact
            active-class="current"
            :class="{ 'mobile': isMobile }"
            style="text-decoration: underline;"
        >
          {{ $t('back') }}
        </router-link>
      </div>

      <div class="expired" v-else-if="depositInfo.isFound && depositInfo.isCompleted">
        <font-awesome-icon style="font-size: 100px; color: rgb(136 199 151);" :icon="['fas', 'check-circle']" />
        <div style="margin: 15px 0;font-size: 25px;">
          <span>{{ $t('completed') }}</span>
        </div>
        <router-link
            :to="$linkCreator($i18n.locale, 'dashboard/wallets/deposit')"
            exact
            active-class="current"
            :class="{ 'mobile': isMobile }"
            style="text-decoration: underline;"
        >
          {{ $t('back') }}
        </router-link>
      </div>

      <div v-else>
        <div class="container" style="margin-bottom: 40px;">

          <div class="col-lg-12 col-md-12 col-xs-12" v-if="depositInfo.memo">
            <div class="alert alert-danger">
              {{ $t('aboutDeposit.memo') }}
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-xs-12" style="margin-bottom: 15px;">
            <div class="section" style="padding-bottom: 0px;">
              <div class="address-info">
                <div class="qr-code">
                  <img :src="qrcode" alt="">
                </div>
                <div class="address">
                  {{ depositInfo.address }}
                  <span @click="copyText(depositInfo.address)" class="copy">
                  <font-awesome-icon
                      :icon="['far', 'copy']"
                      size="lg"
                  />
                </span>
                </div>
                <div style="text-align: center !important;" class="address" v-if="depositInfo.memo">
                  MEMO: {{ depositInfo.memo }}
                  <span @click="copyText(depositInfo.memo)" class="copy">
                  <font-awesome-icon
                      :icon="['far', 'copy']"
                      size="lg"
                  />
                </span>
                </div>
                <div style="text-align: center !important;" class="address">
                  {{ depositInfo.amountCurrency }} {{ depositInfo.currency }}
                  <span @click="copyText(depositInfo.amountCurrency)" class="copy">
                  <font-awesome-icon
                      :icon="['far', 'copy']"
                      size="lg"
                  />
                </span>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-12 col-md-12 col-xs-12">
            <div class="alert alert-primary" v-if="!depositInfo.isCompleted && !depositInfo.isFound">
              <div>
                {{ $t('waiting') }}
                <font-awesome-icon
                    :icon="['far', 'ellipsis-h']"
                    size="lg"
                    style="position: relative; top: 2px;"
                    class="ellipsis"
                />
              </div>
            </div>

            <div class="alert alert-success" v-if="!depositInfo.isCompleted && depositInfo.isFound">
              <div >
                {{ $t('founded') }}<br>{{ $t('founded2', [depositInfo.confirmations, neededConfirmations]) }}
                <font-awesome-icon
                    :icon="['far', 'redo']"
                    size="xs"
                    style="position: relative; margin-left: 5px;"
                    class="redo"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-xs-12">
            <div class="wrapper" style="padding: 0 15px;">
              <div style="text-align: center;border-bottom: 1px solid #e0e1e9;" v-if="!depositInfo.isFound">
                <h3 style="margin: 10px 0;">{{ $t('makepayment') }} <countdown :deadline="depositInfo.deadline" /></h3>
              </div>
              <div style=" padding-top: 20px;text-align: center;">
                <img :src="'/currencies/'+depositInfo.currency+'.png'" alt="">
              </div>
              <div style="font-weight: 700; border-bottom: 1px solid #e0e1e9; padding-bottom: 20px;text-align: center;">
                <h3>
                  {{ $t('aboutDeposit.title', [depositInfo.currency]) }}
                </h3>
                <span style="color: rgba(10,15,56,.7);">{{ $t('aboutDeposit.description', [depositInfo.currency]) }}</span>
              </div>
              <div style="color: rgba(10,15,56,.7); font-weight: 700;">
                <ul style="padding-left: 20px;">
                  <li>{{ $t('aboutDeposit.description2') }}</li>
                  <li>{{ $t('aboutDeposit.description3') }}</li>
                  <li>{{ $t('aboutDeposit.description4') }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </PageContainer>
  </Page>
</template>

<script>
import PageContainer from "@/components/main/UI/PageContainer";
import Page from "@/components/main/UI/Page";
import axios from "@/plugins/axios";
import OnlineTable from "@/components/main/UI/OnlineTable";
import countdown from "@/components/main/UI/countdown";
import * as workerInterval from 'worker-interval';

export default {
  name: "Deposit",
  components: {
    PageContainer,
    Page,
    countdown
  },
  data() {
    return {
      tableData: [],
      tableColumns: [
        {
          name: 'currency',
          title: this.$t('tableColumns.coin'),
          isSortable: true
        },
        {
          name: 'amountCurrency',
          title: this.$t('tableColumns.amount'),
          isSortable: true
        },
        {
          name: 'status',
          title: this.$t('tableColumns.status'),
          isSortable: true
        },
        {
          name: 'date',
          title: this.$t('tableColumns.date'),
          isSortable: true
        }
      ],
      dataLoadingStatus: {
        isDone: false,
        isError: false
      },
      depositInfo: {},
      neededConfirmations: 0,
      qrcode: null
    }
  },
  methods: {
    copyText(item) {
      navigator.clipboard.writeText(item);
      this.$callNotification(this.$i18n.locale, { type: 'info', message: this.$t('copied') })
    },
    async getPaymentInformation() {
      try {
        let fetchedData = await axios.post('main/user/payment', { id: this.$route.params.id });
        if (fetchedData.data.error) {
          this.dataLoadingStatus.isDone = true;
          this.dataLoadingStatus.isError = true;
          return;
        }
        this.depositInfo = fetchedData.data.depositInfo;
        this.qrcode = fetchedData.data.qrcode;
        this.neededConfirmations = fetchedData.data.neededConfirmations;
        this.dataLoadingStatus.isDone = true;
      } catch (error) {
        this.$callNotification(
            {
              type: 'error',
              error: error.data?.error,
              message: error.data?.description
            }
        );
      }
    }
  },
  async mounted() {
    try {
      await this.getPaymentInformation();
      if (this.depositInfo.deadline - Date.now() > 0) this.timer = workerInterval.setInterval(this.getPaymentInformation, 1000 * 30);
    } catch (e) {
      this.dataLoadingStatus.isDone = true;
      this.dataLoadingStatus.isError = true;
    }
  },
  destroyed() {
    workerInterval.clearInterval(this.timer);
  }
}
</script>

<style lang="scss" scoped>

.expired {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 385px) {
  .address {
    text-align: left !important;
  }
}

.address-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #edf0f5;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;

  .qr-code {
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 250px;
    }
  }

  .address {
    background-color: transparent;
    color: #0a0f38;
    padding: 16px;
    position: relative;
    border-top: 1px solid #edf0f5;
    font-size: 13px;
    text-align: center;

    .copy {
      z-index: 9999;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 12px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      -webkit-transition: .25s ease-out;
      -o-transition: .25s ease-out;
      transition: .25s ease-out;
      align-items: center;
      display: flex;
      background: white;
    }
  }
}


.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;

  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}


.ellipsis {
  opacity: 1;
  animation: fade 2s linear infinite;
}

.redo {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}
</style>