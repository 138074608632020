<template>
  <span>{{ this.deadline - Date.now() > 0 ? countDown  : 1 | toDate('mm:ss') }}</span>
</template>

<script>
import * as workerTimers from 'worker-timers';

export default {
  name: "countdown",
  props: {
    deadline: Number
  },
  data() {
    return {
      countDown: this.deadline - Date.now(),
      oldDeadline: this.deadline,
      timer: null
    }
  },
  methods: {
    countDownTimer() {
      this.timer = workerTimers.setInterval(() => {
        if (this.deadline - Date.now() <= 0) return workerTimers.clearInterval(this.timer);
        this.countDown = this.deadline - Date.now();
      }, 1000);
    }
  },
  mounted() {
    this.countDownTimer()
  },
  updated() {
    if (this.oldDeadline !== this.deadline) {
      this.oldDeadline = this.deadline;
      this.countDown = this.deadline - Date.now();
      workerTimers.clearInterval(this.timer);
      this.countDownTimer();
    }
  },
  destroyed() {
    workerTimers.clearInterval(this.timer);
  }
}
</script>

<style scoped>

</style>
